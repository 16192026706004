/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import "./layout.css"

const Wrapper = ({ children }) => {

  return (
    <>
      
      <div className="container xl:max-w-7xl md:max-w-5xl mx-auto px-4">
       {children}
      </div>
   
    </>
  )
}

export default Wrapper
