import * as React from "react"
import Seo from "../../components/seo"
import News from "../../components/news";
import Layout from "../../components/layout";
import Pricelist from "../../components/pricelist";


const LandingPage = () => {
    return (
        <Layout>
            <Pricelist tags="rent"></Pricelist>
        </Layout>
    )
}

export const Head = () => <Seo title="Home" />

export default LandingPage
